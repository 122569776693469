// src/standalone/lavaisland/StandaloneLavaActivity3.js
import LavaActivity3 from '../../pages/lava-island/lava-activity3';

const StandaloneLavaActivity3 = () => {
  return (
    <div className="min-h-screen">
      <LavaActivity3 hideNavigation={true} />
    </div>
  );
};

export default StandaloneLavaActivity3;