import React, { useState, useEffect } from 'react';
import { Home, Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';

const TogetherActivity3 = ({ hideNavigation = false }) => {
  const navigate = useNavigate();

  const [showTutorial, setShowTutorial] = useState(true);
  const [currentItem, setCurrentItem] = useState(0);
  const [score, setScore] = useState(0);
  const [showCelebration, setShowCelebration] = useState(false);
  const [helpingActions, setHelpingActions] = useState([]);

  // New states for improvements
  const [showHint, setShowHint] = useState(false);
  const [incorrectMessage, setIncorrectMessage] = useState(false);

  const classroomItems = [
    {
      id: 1,
      name: 'Books',
      needsHelp: 'The bookshelf is messy',
      correctSpot: 'shelf',
      image: '📚',
      hint: 'Books belong on the shelf where everyone can find them!'
    },
    {
      id: 2,
      name: 'Crayons',
      needsHelp: 'Crayons are scattered',
      correctSpot: 'box',
      image: '🖍️',
      hint: 'Crayons go in their box so everyone can use them!'
    },
    {
      id: 3,
      name: 'Chair',
      needsHelp: 'Chair is out of place',
      correctSpot: 'desk',
      image: '🪑',
      hint: 'Push in chairs to keep paths clear for friends!'
    },
    {
      id: 4,
      name: 'Plant',
      needsHelp: 'Plant needs water',
      correctSpot: 'window',
      image: '🌱',
      hint: 'Plants need water and sunlight to grow happy!'
    }
  ];

  const handleBackToMain = () => {
    navigate(hideNavigation ? '/standalone/together-land' : '/together-land');
  };

  const handleSpotClick = (spot) => {
    const currentItemData = classroomItems[currentItem];
    const isCorrect = spot === currentItemData.correctSpot;

    if (isCorrect) {
      setScore((prev) => prev + 1);
      setHelpingActions((prev) => [
        ...prev,
        {
          item: currentItemData.name,
          action: `You helped organize the ${currentItemData.name.toLowerCase()}!`,
          icon: currentItemData.image
        }
      ]);

      // Move to next item or show celebration if it's the last
      if (currentItem === classroomItems.length - 1) {
        setShowCelebration(true);
      } else {
        setCurrentItem((prev) => prev + 1);
      }
      setIncorrectMessage(false);
    } else {
      // Show incorrect message briefly
      setIncorrectMessage(true);
      setTimeout(() => {
        setIncorrectMessage(false);
      }, 1500);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-yellow-300 to-amber-400">
      {!hideNavigation && <Header />}
      {!hideNavigation && <NavBar />}

      <main className="flex-1 relative px-4 py-8">
        {/* Back button */}
        <button
          onClick={handleBackToMain}
          className="absolute top-4 left-4 bg-white/80 hover:bg-white text-amber-700 font-bold px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 border-2 border-amber-500 shadow-md flex items-center gap-2"
        >
          <Home className="w-5 h-5" /> Back to Together Land
        </button>

        <div className="max-w-4xl mx-auto space-y-8 mt-16">
          {/* Title */}
          <div className="text-center bg-white/10 backdrop-blur-md border border-white/20 rounded-xl p-8 shadow-lg">
            <h1 className="text-4xl font-bold text-white mb-4">Helping Hands Heroes! 🦸‍♂️</h1>
            <p className="text-xl text-amber-900">
              Help make our classroom a happy place for everyone!
            </p>
          </div>

          {/* Game Area */}
          <div className="bg-white/10 backdrop-blur-md rounded-xl p-6 border border-white/20 relative">
            {!showCelebration && (
              <>
                {/* Progress and Current Item Display */}
                <div className="flex justify-between items-center mb-6">
                  <div className="text-white font-bold">
                    Item {currentItem + 1} of {classroomItems.length}
                  </div>
                  <div className="text-white font-bold">
                    Score: {score}
                  </div>
                </div>

                <div className="text-center mb-4">
                  <div className="text-6xl mb-4">
                    {classroomItems[currentItem].image}
                  </div>
                  <h2 className="text-2xl text-white font-bold mb-2">
                    {classroomItems[currentItem].needsHelp}
                  </h2>
                  <p className="text-amber-900 text-lg">
                    Where should we put this to help our class?
                  </p>
                </div>

                {/* Classroom Spots */}
                <div className="grid grid-cols-2 gap-4 max-w-2xl mx-auto mb-4">
                  {['shelf', 'box', 'desk', 'window'].map((spot) => (
                    <button
                      key={spot}
                      onClick={() => handleSpotClick(spot)}
                      className="bg-white/10 hover:bg-white/20 border border-white/20 rounded-xl p-6 transition-all duration-300 transform hover:scale-105 relative"
                    >
                      <div className="text-4xl mb-2 flex justify-center">
                        {{
                          shelf: '📚',
                          box: '📦',
                          desk: '🪑',
                          window: '🪟'
                        }[spot]}
                      </div>
                      <div className="text-white font-bold capitalize text-center">
                        The {spot}
                      </div>
                    </button>
                  ))}
                </div>

                {/* Incorrect Feedback */}
                {incorrectMessage && (
                  <div className="text-center text-red-500 animate-fade-in">
                    Oops! That’s not the right spot. Try again!
                  </div>
                )}

                {/* Helper Hint */}
                <div className="mt-6 text-center">
                  <button
                    className="text-amber-900 hover:text-white transition-colors underline"
                    onClick={() => setShowHint(true)}
                  >
                    Need a hint? 💡
                  </button>
                </div>
              </>
            )}
          </div>

          {/* Helping Actions Log */}
          <div className="bg-white/10 backdrop-blur-md rounded-xl p-4 border border-white/20">
            <h3 className="text-white font-bold mb-2">Your Helpful Actions:</h3>
            <div className="space-y-2">
              {helpingActions.map((action, index) => (
                <div 
                  key={index}
                  className="flex items-center gap-2 text-amber-900 animate-pop-in"
                >
                  <span>{action.icon}</span>
                  <span>{action.action}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Tutorial Modal */}
        {showTutorial && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white shadow-xl p-8 rounded-xl text-center max-w-lg mx-4 relative">
              <button
                onClick={() => setShowTutorial(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              >
                ✕
              </button>
              
              <div className="mb-6">
                <div className="w-20 h-20 bg-amber-900 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Star className="w-10 h-10 text-amber-600" />
                </div>
                <h2 className="text-3xl font-bold text-gray-800 mb-2">
                  Be a Helping Hand Hero! 🦸‍♂️
                </h2>
                <p className="text-gray-600">Let's help keep our classroom neat and tidy for everyone!</p>
              </div>

              <div className="text-left space-y-4 mb-8">
                <p className="text-gray-600">
                  1. Look at what needs help 👀
                </p>
                <p className="text-gray-600">
                  2. Click where it should go! 🎯
                </p>
                <p className="text-gray-600">
                  3. Watch how your helping makes the classroom better! ✨
                </p>
              </div>

              <button
                onClick={() => setShowTutorial(false)}
                className="bg-amber-500 hover:bg-amber-600 text-white px-8 py-4 rounded-full text-lg font-bold transition-all duration-300 hover:scale-105 shadow-lg hover:shadow-xl"
              >
                Let's Start Helping! 🌟
              </button>
            </div>
          </div>
        )}

        {/* Celebration Modal */}
        {showCelebration && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white shadow-xl p-8 rounded-xl text-center max-w-lg mx-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                You're a Super Helper! 🌟
              </h2>
              <p className="text-gray-600 text-lg mb-6">
                You helped make our classroom a better place for everyone!
              </p>
              <div className="space-y-4 mb-8">
                {helpingActions.map((action, index) => (
                  <div key={index} className="flex items-center gap-2 justify-center text-gray-700">
                    <span>{action.icon}</span>
                    <span>{action.action}</span>
                  </div>
                ))}
              </div>
              <div className="flex gap-4 justify-center">
                <button
                  onClick={() => {
                    setCurrentItem(0);
                    setScore(0);
                    setHelpingActions([]);
                    setShowCelebration(false);
                  }}
                  className="bg-amber-500 hover:bg-amber-600 text-white px-6 py-3 rounded-full transition-all duration-300 hover:scale-105"
                >
                  Play Again! 🎮
                </button>
                <button
                  onClick={handleBackToMain}
                  className="bg-amber-500 hover:bg-amber-600 text-white px-6 py-3 rounded-full transition-all duration-300 hover:scale-105"
                >
                  Back to Together Land 🏠
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Hint Modal */}
        {showHint && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white shadow-xl p-6 rounded-xl max-w-md w-full mx-4 text-center relative animate-pop-in">
              <button
                onClick={() => setShowHint(false)}
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
              >
                ✕
              </button>
              <h2 className="text-xl font-bold text-gray-800 mb-4">Hint</h2>
              <p className="text-gray-700 text-lg">
                {classroomItems[currentItem].hint}
              </p>
              <button
                onClick={() => setShowHint(false)}
                className="mt-6 bg-amber-500 hover:bg-amber-600 text-white px-6 py-2 rounded-full font-bold transition-all duration-300 hover:scale-105 shadow-md"
              >
                Got it!
              </button>
            </div>
          </div>
        )}
      </main>

      {/* <Footer /> */}
      {!hideNavigation && <Footer />}

      <style jsx global>{`
        @keyframes pop-in {
          0% { transform: scale(0.8); opacity: 0; }
          100% { transform: scale(1); opacity: 1; }
        }
        .animate-pop-in {
          animation: pop-in 0.3s ease-out forwards;
        }

        @keyframes fade-in {
          0% { opacity: 0; }
          100% { opacity: 1; }
        }
        .animate-fade-in {
          animation: fade-in 0.5s ease-in forwards;
        }
      `}</style>
    </div>
  );
};

export default TogetherActivity3;