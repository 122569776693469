// src/standalone/lavaisland/StandaloneLavaActivity1.js
import LavaActivity1 from '../../pages/lava-island/lava-activity1';

const StandaloneLavaActivity1 = () => {
  return (
    <div className="min-h-screen">
      <LavaActivity1 hideNavigation={true} />
    </div>
  );
};

export default StandaloneLavaActivity1;