import { Home, Sparkles, RefreshCcw } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';

const TogetherActivity2 = ({ hideNavigation = false }) => {
  const navigate = useNavigate();

  // State Hooks
  const [activeEmotion, setActiveEmotion] = useState(null);
  const [emotionBubbles, setEmotionBubbles] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [showTutorial, setShowTutorial] = useState(true);

  // NEW STATES:
  const [activeCollisions, setActiveCollisions] = useState(new Set());
  const [discoveredCombos, setDiscoveredCombos] = useState(new Set());

  const [touchStartPos, setTouchStartPos] = useState(null);
  const playAreaRef = useRef(null);
  const dragRef = useRef(null);

  const emotions = {
    joy: { color: '#CFD705', emoji: '😊', energy: 'bright', name: 'Joy' },
    love: { color: '#FF69B4', emoji: '❤️', energy: 'warm', name: 'Love' },
    calm: { color: '#87CEEB', emoji: '😌', energy: 'peaceful', name: 'Calm' },
    excited: { color: '#FF7F50', emoji: '🤩', energy: 'vibrant', name: 'Excited' },
    proud: { color: '#9370DB', emoji: '🌟', energy: 'glowing', name: 'Pride' },
    brave: { color: '#FF6B6B', emoji: '💪', energy: 'strong', name: 'Brave' },
    curious: { color: '#98FB98', emoji: '🤔', energy: 'sparkling', name: 'Curious' },
    grateful: { color: '#DDA0DD', emoji: '🙏', energy: 'gentle', name: 'Grateful' },
    hopeful: { color: '#40E0D0', emoji: '🌈', energy: 'inspiring', name: 'Hope' },
    silly: { color: '#FFA07A', emoji: '🤪', energy: 'playful', name: 'Silly' }
  };

  // Updated reaction combinations for SEL
  const reactionTypes = {
    // Brave Combinations
    'brave-calm': 'Quiet courage that helps us feel safe and steady inside.',
    'brave-curious': 'A bold explorer`s heart, ready to safely discover new ideas.',
    'brave-excited': 'Daring spirit bursting with energy to try new adventures.',
    'brave-grateful': 'Thankfulness for our own courage, making us feel stronger.',
    'brave-hopeful': 'Looking ahead with confidence that tomorrow can be brighter.',
    'brave-joy': 'Happy bravery lighting the way with a warm, confident smile.',
    'brave-love': 'Caring courage that stands up for friends and loved ones.',
    'brave-proud': 'A lion`s roar of believing in ourselves and our strengths.',
    'brave-silly': 'Fearless fun that`s not afraid to laugh and play freely.',

    // Calm Combinations
    'calm-curious': 'Peaceful wondering that enjoys quiet moments of discovery.',
    'calm-excited': 'Gentle energy that glows softly while looking forward to good things.',
    'calm-grateful': 'Quiet appreciation that warms our hearts with thankfulness.',
    'calm-hopeful': 'A soft, steady faith that tomorrow will bring gentle joys.',
    'calm-joy': 'A soothing happiness that helps everyone feel safe and welcome.',
    'calm-love': 'Comforting kindness that cares without rushing or worrying.',
    'calm-proud': 'A peaceful pride that stands tall while staying kind and patient.',
    'calm-silly': 'Relaxed fun that sparks giggles in a gentle, friendly way.',

    // Curious Combinations
    'curious-excited': 'Joyful eagerness to learn and discover new things every day.',
    'curious-grateful': 'Wonder mixed with thankfulness for all we can explore.',
    'curious-hopeful': 'Exploring tomorrow`s questions with bright eyes and big dreams.',
    'curious-joy': 'A happy spark that laughs while searching for answers.',
    'curious-love': 'A caring heart that loves to learn about others and connect.',
    'curious-proud': 'Feeling good about being brave enough to ask new questions.',
    'curious-silly': 'Funny questions and playful thinking that make learning fun.',

    // Excited Combinations
    'excited-grateful': 'Bubbly thankfulness that can`t wait to celebrate every little gift.',
    'excited-hopeful': 'Bright optimism that looks ahead with a big smile.',
    'excited-joy': 'Jumping smiles and bouncing energy that light up the room.',
    'excited-love': 'A caring spirit that`s eager to give hugs and high-fives.',
    'excited-proud': 'Cheering ourselves on for each step we bravely take forward.',
    'excited-silly': 'Uncontainable laughter and playful hearts having the best time.',

    // Grateful Combinations
    'grateful-hopeful': 'A thankful heart believing tomorrow can be even brighter.',
    'grateful-joy': 'Pure happiness for all the good we already have.',
    'grateful-love': 'Warm appreciation for the kindness we share with each other.',
    'grateful-proud': 'Feeling thankful for who we are and all we can do.',
    'grateful-silly': 'Giggly thanks for the small funny moments each day brings.',

    // Hopeful Combinations
    'hopeful-joy': 'A shining belief that there`s always a reason to smile.',
    'hopeful-love': 'Trust in a brighter tomorrow shared with caring hearts.',
    'hopeful-proud': 'Confidence in our dreams that helps us stand tall.',
    'hopeful-silly': 'Playful excitement about all the fun things tomorrow might bring.',

    // Joy Combinations
    'joy-love': 'Hearts glowing with laughter and kindness for one another.',
    'joy-proud': 'Big smiles celebrating all we`ve accomplished, big or small.',
    'joy-silly': 'Bubbly giggles and fun that spread happiness everywhere.',

    // Love Combinations
    'love-proud': 'A caring heart, proud of all the little ways we show kindness.',
    'love-silly': 'Goofy affection that shares jokes, hugs, and smiles together.',

    // Proud Combination
    'proud-silly': 'Feeling good about ourselves while having a big belly laugh.'
    };

  const handleBackToMain = () => {
    navigate(hideNavigation ? '/standalone/together-land' : '/together-land');
  };

  // Touch event handlers for mobile support
  const handleTouchStart = (e, emotion) => {
    e.preventDefault();
    setActiveEmotion(emotion);
    const touch = e.touches[0];
    setTouchStartPos({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (!activeEmotion || !playAreaRef.current) return;
    
    const touch = e.touches[0];
    const rect = playAreaRef.current.getBoundingClientRect();
    const x = ((touch.clientX - rect.left) / rect.width) * 100;
    const y = ((touch.clientY - rect.top) / rect.height) * 100;
    
    dragRef.current = { x, y };
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    if (activeEmotion && dragRef.current) {
      createBubble(dragRef.current.x, dragRef.current.y);
    }
    setActiveEmotion(null);
    setTouchStartPos(null);
    dragRef.current = null;
  };

  // Mouse event handlers
  const startDrag = (emotion) => {
    setActiveEmotion(emotion);
  };

  const onDrag = (e) => {
    if (!activeEmotion || !playAreaRef.current) return;
    
    const rect = playAreaRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    
    dragRef.current = { x, y };
  };

  const endDrag = () => {
    if (!activeEmotion || !dragRef.current) return;
    createBubble(dragRef.current.x, dragRef.current.y);
  };

  const createBubble = (x, y) => {
    const newBubble = {
      id: Date.now(),
      emotion: activeEmotion,
      x,
      y,
      scale: 1,
      velocity: {
        x: (Math.random() * 1.5 - 0.75) * 2,
        y: (Math.random() * 1.5 - 0.75) * 2
      }
    };

    setEmotionBubbles((prev) => [...prev, newBubble]);
    setActiveEmotion(null);
    dragRef.current = null;
  };

  // Collision checking
  useEffect(() => {
    const collisionInterval = setInterval(() => {
      setEmotionBubbles((prev) => {
        const bubbles = [...prev];
        const newCollisions = new Set();
        
        // Check each pair of bubbles for collisions
        for (let i = 0; i < bubbles.length; i++) {
          for (let j = i + 1; j < bubbles.length; j++) {
            const b1 = bubbles[i];
            const b2 = bubbles[j];
            
            const collisionKey = `${Math.min(b1.id, b2.id)}-${Math.max(b1.id, b2.id)}`;
            
            if (isNearby(b1, b2)) {
              newCollisions.add(collisionKey);

              // Only create reaction if this is a new collision
              if (!activeCollisions.has(collisionKey)) {
                createReaction(b1, b2);
              }
            }
          }
        }
        
        setActiveCollisions(newCollisions);
        return bubbles;
      });
    }, 100);

    return () => clearInterval(collisionInterval);
  }, [activeCollisions]);

  const isNearby = (bubble1, bubble2) => {
    const dx = bubble1.x - bubble2.x;
    const dy = bubble1.y - bubble2.y;
    return Math.sqrt(dx * dx + dy * dy) < 15; // Adjust collision distance if needed
  };

  const createReaction = (bubble1, bubble2) => {
    const combo = [bubble1.emotion, bubble2.emotion].sort().join('-');
  
    // Only create reaction if this combination hasn't been discovered
    if (!discoveredCombos.has(combo)) {
      setDiscoveredCombos((prev) => new Set(prev).add(combo));
      
      const reaction = {
        id: Date.now(),
        x: (bubble1.x + bubble2.x) / 2,
        y: (bubble1.y + bubble2.y) / 2,
        message: reactionTypes[combo] ||
          `${emotions[bubble1.emotion].emoji}+${emotions[bubble2.emotion].emoji} Make something special!`,
        combo,
        timestamp: Date.now()
      };

      setReactions((prev) => [...prev, reaction]);

      // Remove reaction after animation
      setTimeout(() => {
        setReactions((prev) => prev.filter((r) => r.id !== reaction.id));
      }, 2000);
    }
  };

  // Handle bubble movement
  useEffect(() => {
    const interval = setInterval(() => {
      setEmotionBubbles((prev) =>
        prev.map((bubble) => {
          let newX = bubble.x + bubble.velocity.x;
          let newY = bubble.y + bubble.velocity.y;
          let newVelocityX = bubble.velocity.x;
          let newVelocityY = bubble.velocity.y;

          // Boundary checks with padding
          if (newX <= 10) {
            newX = 10;
            newVelocityX = Math.abs(bubble.velocity.x) * 0.8;
          } else if (newX >= 90) {
            newX = 90;
            newVelocityX = -Math.abs(bubble.velocity.x) * 0.8;
          }

          if (newY <= 10) {
            newY = 10;
            newVelocityY = Math.abs(bubble.velocity.y) * 0.8;
          } else if (newY >= 90) {
            newY = 90;
            newVelocityY = -Math.abs(bubble.velocity.y) * 0.8;
          }

          return {
            ...bubble,
            x: newX,
            y: newY,
            velocity: {
              x: newVelocityX,
              y: newVelocityY
            }
          };
        })
      );
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-yellow-300 to-amber-400">
      {!hideNavigation && <Header />}
      {!hideNavigation && <NavBar />}

      <main className="flex-1 relative px-4 py-8">
        {/* Back button */}
        <button
          onClick={handleBackToMain}
          className="absolute top-4 left-4 bg-white/80 hover:bg-white text-amber-700 font-bold px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 border-2 border-amber-500 shadow-md flex items-center gap-2"
        >
          <Home className="w-5 h-5" /> Back to Together Land
        </button>

        <div className="max-w-6xl mx-auto space-y-8 mt-16">
          {/* Title */}
          <div className="text-center bg-white/10 backdrop-blur-md border border-white/20 rounded-xl p-8 shadow-lg">
            <h1 className="text-4xl font-bold text-white mb-4">Emotion Energy Lab ⚡️</h1>
            <p className="text-xl text-amber-900">
              Mix and match emotions to create amazing reactions!
            </p>
          </div>

          {/* Emotion Palette - with mobile support */}
          <div className="flex flex-wrap justify-center gap-4 mb-6">
            {Object.entries(emotions).map(([name, data]) => (
              <div
                key={name}
                draggable
                onDragStart={() => startDrag(name)}
                onTouchStart={(e) => handleTouchStart(e, name)}
                className={`p-4 md:p-6 rounded-xl cursor-grab transition-all duration-300 hover:scale-110
                  ${activeEmotion === name ? 'ring-4 ring-white scale-110' : ''}
                  transform hover:-translate-y-2`}
                style={{ backgroundColor: data.color }}
              >
                <span className="text-3xl md:text-4xl">{data.emoji}</span>
                <p className="text-white font-bold mt-2 text-sm md:text-base">{data.name}</p>
              </div>
            ))}
          </div>

          {/* Play Area */}
          <div
            ref={playAreaRef}
            className="relative h-96 bg-white/5 backdrop-blur-sm rounded-3xl overflow-hidden border border-white/10 shadow-xl"
            onDragOver={(e) => {
              e.preventDefault();
              onDrag(e);
            }}
            onDrop={(e) => {
              e.preventDefault();
              endDrag();
            }}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {emotionBubbles.map((bubble) => (
              <div
                key={bubble.id}
                className="absolute transition-transform duration-300"
                style={{
                  left: `${bubble.x}%`,
                  top: `${bubble.y}%`,
                  transform: `translate(-50%, -50%) scale(${bubble.scale})`
                }}
              >
                <div
                  className="w-12 h-12 rounded-full flex items-center justify-center text-2xl animate-bounce-slow"
                  style={{ backgroundColor: emotions[bubble.emotion].color }}
                >
                  {emotions[bubble.emotion].emoji}
                </div>
              </div>
            ))}

            {/* Reaction Messages */}
            {reactions.map((reaction) => (
              <div
                key={reaction.id}
                className="absolute z-10 transition-all duration-500 animate-float-up"
                style={{
                  left: `${reaction.x}%`,
                  top: `${reaction.y}%`,
                  transform: 'translate(-50%, -50%)'
                }}
              >
                <div className="bg-white shadow-lg rounded-xl px-4 py-2 text-gray-800 text-lg whitespace-nowrap">
                  {reaction.message}
                </div>
              </div>
            ))}

            {emotionBubbles.length === 0 && (
              <div className="absolute inset-0 flex items-center justify-center">
                <p className="text-amber-900 text-xl text-center">
                  Drag or tap emotions here and watch them interact! ✨
                </p>
              </div>
            )}
          </div>

          {/* Controls */}
          <div className="flex justify-between items-center bg-white/10 backdrop-blur-md rounded-xl p-4">
            <div className="text-white">
              <span className="font-bold">Discoveries:</span> {discoveredCombos.size}/{Object.keys(reactionTypes).length}
            </div>
            <button
              onClick={() => {
                setEmotionBubbles([]);
                setActiveCollisions(new Set());
                // Optionally reset discoveries:
                // setDiscoveredCombos(new Set());
              }}
              className="flex items-center gap-2 text-white hover:text-amber-200"
            >
              <RefreshCcw className="w-5 h-5" /> Clear Lab
            </button>
          </div>
        </div>

        {/* Tutorial Modal */}
        {showTutorial && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white shadow-xl p-8 rounded-xl text-center max-w-lg mx-4 relative">
              {/* Close button */}
              <button
                onClick={() => setShowTutorial(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              >
                ✕
              </button>
              
              {/* Header */}
              <div className="mb-6">
                <div className="w-20 h-20 bg-amber-900 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Sparkles className="w-10 h-10 text-amber-600" />
                </div>
                <h2 className="text-3xl font-bold text-gray-800 mb-2">
                  Welcome to the Emotion Energy Lab! ⚡️
                </h2>
                <p className="text-gray-600">Let's explore how different emotions can work together!</p>
              </div>

              {/* Tutorial Steps */}
              <div className="space-y-6 text-left mb-8">
                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 bg-amber-900 rounded-full flex items-center justify-center flex-shrink-0 mt-1">
                    <span className="text-amber-600 font-bold">1</span>
                  </div>
                  <div>
                    <h3 className="font-bold text-gray-800 mb-1">Pick Your Emotions</h3>
                    <p className="text-gray-600">Drag or tap different emotions into the lab area 🔬</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 bg-amber-900 rounded-full flex items-center justify-center flex-shrink-0 mt-1">
                    <span className="text-amber-600 font-bold">2</span>
                  </div>
                  <div>
                    <h3 className="font-bold text-gray-800 mb-1">Watch Them Move</h3>
                    <p className="text-gray-600">See your emotions bounce and float around ✨</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 bg-amber-900 rounded-full flex items-center justify-center flex-shrink-0 mt-1">
                    <span className="text-amber-600 font-bold">3</span>
                  </div>
                  <div>
                    <h3 className="font-bold text-gray-800 mb-1">Create Reactions</h3>
                    <p className="text-gray-600">When emotions meet, they make something special! 🎯</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 bg-amber-900 rounded-full flex items-center justify-center flex-shrink-0 mt-1">
                    <span className="text-amber-600 font-bold">4</span>
                  </div>
                  <div>
                    <h3 className="font-bold text-gray-800 mb-1">Discover Combinations</h3>
                    <p className="text-gray-600">Try to find all the different emotion pairs! 🌈</p>
                  </div>
                </div>
              </div>

              {/* Start Button */}
              <button
                onClick={() => setShowTutorial(false)}
                className="bg-amber-500 hover:bg-amber-600 text-white px-8 py-4 rounded-full text-lg font-bold transition-all duration-300 hover:scale-105 shadow-lg hover:shadow-xl"
              >
                Let's Start Experimenting! 🧪
              </button>
            </div>
          </div>
        )}
      </main>

      {/* <Footer /> */}
      {!hideNavigation && <Footer />}

      <style jsx global>{`
        @keyframes bounce-slow {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
        
        @keyframes float-up {
          0% { opacity: 0; transform: translate(-50%, 20px); }
          10% { opacity: 1; transform: translate(-50%, -50%); }
          90% { opacity: 1; transform: translate(-50%, -50%); }
          100% { opacity: 0; transform: translate(-50%, -120px); }
        }

        .animate-bounce-slow {
          animation: bounce-slow 3s infinite;
        }

        .animate-float-up {
          animation: float-up 2s forwards;
        }

        /* Mobile touch handling */
        @media (max-width: 768px) {
          .cursor-grab {
            cursor: pointer;
          }
        }
      `}</style>
    </div>
  );
};

export default TogetherActivity2;