// src/standalone/StandaloneBuddyActivity1.js
import BuddyActivity1 from '../../pages/buddy-island/buddy-activity1';

const StandaloneBuddyActivity1 = () => {
  return (
    <div className="min-h-screen">
      <BuddyActivity1 hideNavigation={true} />
    </div>
  );
};

export default StandaloneBuddyActivity1;