// src/standalone/lavaisland/StandaloneLavaActivity2.js
import LavaActivity2 from '../../pages/lava-island/lava-activity2';

const StandaloneLavaActivity2 = () => {
  return (
    <div className="min-h-screen">
      <LavaActivity2 hideNavigation={true} />
    </div>
  );
};

export default StandaloneLavaActivity2;