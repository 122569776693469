// src/standalone/StandaloneBuddyActivity2.js
import BuddyActivity2 from '../../pages/buddy-island/buddy-activity2';

const StandaloneBuddyActivity2 = () => {
  return (
    <div className="min-h-screen">
      <BuddyActivity2 hideNavigation={true} />
    </div>
  );
};

export default StandaloneBuddyActivity2;