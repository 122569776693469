// src/standalone/togetherland/StandaloneTogetherActivity2.js
import TogetherActivity2 from "../../pages/together-land/together-activity2";

const StandaloneTogetherActivity2 = () => {
  return (
    <div className="min-h-screen">
      <TogetherActivity2 hideNavigation={true} />
    </div>
  );
};

export default StandaloneTogetherActivity2;