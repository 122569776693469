import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SearchProvider } from './search/SearchProvider';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import About from './pages/About';
import Home from './pages/Home';
import WellnessMetropolis from './pages/OnlineClasses';
import EmotionsFalls from './pages/EmotionsFalls';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import CommunityChallenges from './pages/CommunityChallenges';
import ChallengeDetail from './pages/ChallengeDetail';
import ClassroomTools from './pages/ClassroomTools';
import LavaIsland from './pages/LavaIsland';
import TogetherLand from './pages/TogetherLand';
import BuddyIsland from './pages/BuddyIsland';
import LavaActivity1 from './pages/lava-island/lava-activity1';
import LavaActivity2 from './pages/lava-island/lava-activity2';
import LavaActivity3 from './pages/lava-island/lava-activity3';
import BuddyActivity1 from './pages/buddy-island/buddy-activity1';
import BuddyActivity2 from './pages/buddy-island/buddy-activity2';
import BuddyActivity3 from './pages/buddy-island/buddy-activity3';
import TogetherActivity1 from './pages/together-land/together-activity1';
import TogetherActivity2 from './pages/together-land/together-activity2';
import TogetherActivity3 from './pages/together-land/together-activity3';

// TEMP
import StandaloneBuddyIsland from './standalone/buddyisland/StandaloneBuddyIsland';
import StandaloneBuddyActivity1 from './standalone/buddyisland/StandaloneBuddyActivity1';
import StandaloneBuddyActivity2 from './standalone/buddyisland/StandaloneBuddyActivity2';
import StandaloneBuddyActivity3 from './standalone/buddyisland/StandaloneBuddyActivity3';

import StandaloneLavaIsland from './standalone/lavaisland/StandaloneLavaIsland';
import StandaloneLavaActivity1 from './standalone/lavaisland/StandaloneLavaActivity1';
import StandaloneLavaActivity2 from './standalone/lavaisland/StandaloneLavaActivity2';
import StandaloneLavaActivity3 from './standalone/lavaisland/StandaloneLavaActivity3';

import StandaloneTogetherLand from './standalone/togetherland/StandaloneTogetherLand';
import StandaloneTogetherActivity1 from './standalone/togetherland/StandaloneTogetherActivity1';
import StandaloneTogetherActivity2 from './standalone/togetherland/StandaloneTogetherActivity2';
import StandaloneTogetherActivity3 from './standalone/togetherland/StandaloneTogetherActivity3';



function App() {
  return (
    <AuthProvider>
      <SearchProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/community-challenges" element={<CommunityChallenges />} />
            <Route path="/challenge/:month" element={<ChallengeDetail />} />
            <Route path="/online-classes" element={<WellnessMetropolis />} />

            {/* Emotions Falls protected route */}
            <Route 
              path="/emotions-falls" 
              element={
                <ProtectedRoute moduleId="emotions-falls">
                  <EmotionsFalls />
                </ProtectedRoute>
              } 
            />

            {/* Together Land protected routes */}
            <Route 
              path="/together-land" 
              element={
                <ProtectedRoute moduleId="together-land">
                  <TogetherLand />
                </ProtectedRoute>
              } 
            />

            {/* Lava Island protected routes */}
            <Route 
              path="/lava-island" 
              element={
                <ProtectedRoute moduleId="lava-island">
                  <LavaIsland />
                </ProtectedRoute>
              } 
            />

            {/* Buddy Island protected route */}
            <Route 
              path="/buddy-island" 
              element={
                <ProtectedRoute moduleId="buddy-island">
                  <BuddyIsland />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/lava-island/lava-activity1" 
              element={
                <ProtectedRoute moduleId="lava-island">
                  <LavaActivity1 />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/lava-island/lava-activity2" 
              element={
                <ProtectedRoute moduleId="lava-island">
                  <LavaActivity2 />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/lava-island/lava-ctivity3" 
              element={
                <ProtectedRoute moduleId="lava-island">
                  <LavaActivity3 />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/buddy-island/buddy-activity1" 
              element={
                <ProtectedRoute moduleId="buddy-island">
                  <BuddyActivity1 />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/buddy-island/buddy-activity2" 
              element={
                <ProtectedRoute moduleId="buddy-island">
                  <BuddyActivity2 />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/buddy-island/buddy-activity3" 
              element={
                <ProtectedRoute moduleId="buddy-island">
                  <BuddyActivity3 />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/together-land/together-activity1" 
              element={
                <ProtectedRoute moduleId="together-land">
                  <TogetherActivity1 />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/together-land/together-activity2" 
              element={
                <ProtectedRoute moduleId="together-land">
                  <TogetherActivity2 />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/together-land/together-activity3" 
              element={
                <ProtectedRoute moduleId="together-land">
                  <TogetherActivity3 />
                </ProtectedRoute>
              } 
            />


            <Route 
              path="/standalone/buddy-island" 
              element={<StandaloneBuddyIsland />} 
            />
            <Route 
              path="/standalone/buddy-island/buddy-activity1" 
              element={<StandaloneBuddyActivity1 />} 
            />
            <Route 
              path="/standalone/buddy-island/buddy-activity2" 
              element={<StandaloneBuddyActivity2 />} 
            />
            <Route 
              path="/standalone/buddy-island/buddy-activity3" 
              element={<StandaloneBuddyActivity3 />} 
            />
            <Route 
              path="/standalone/lava-island" 
              element={<StandaloneLavaIsland />} 
            />
            <Route 
              path="/standalone/lava-island/lava-activity1" 
              element={<StandaloneLavaActivity1 />} 
            />
            <Route 
              path="/standalone/lava-island/lava-activity2" 
              element={<StandaloneLavaActivity2 />} 
            />
            <Route 
              path="/standalone/lava-island/lava-activity3" 
              element={<StandaloneLavaActivity3 />} 
            />
            <Route 
              path="/standalone/together-land" 
              element={<StandaloneTogetherLand />} 
            />
            <Route 
              path="/standalone/together-land/together-activity1" 
              element={<StandaloneTogetherActivity1 />} 
            />
            <Route 
              path="/standalone/together-land/together-activity2" 
              element={<StandaloneTogetherActivity2 />} 
            />
            <Route 
              path="/standalone/together-land/together-activity3" 
              element={<StandaloneTogetherActivity3 />} 
            />


            <Route path="/classroom-tools" element={<ClassroomTools />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
          </Routes>
        </Router>
      </SearchProvider>
    </AuthProvider>
  );
}

export default App;