import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
// import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { Sparkles, Star, Heart, Sun } from 'lucide-react';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';

// MultiBackend setup for touch and HTML5 backends
const HTML5toTouch = {
  backends: [
    { backend: HTML5Backend },
    { backend: TouchBackend, options: { enableMouseEvents: true } }
  ],
};

// Modal Component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl p-8 max-w-md w-full mx-4 shadow-xl">
        {children}
      </div>
    </div>
  );
};

// Certificate Component
const Certificate = ({ studentName, date }) => (
  <div className="bg-gradient-to-br from-yellow-50 to-pink-50 p-8 relative border-8 border-yellow-400 rounded-xl">
    <div className="absolute inset-0 m-4 border-2 border-yellow-400 rounded-lg" />
    <div className="text-center space-y-6">
      <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-pink-500 mb-8">
        Amazing Job! 🌟
      </h1>
      <p className="text-2xl font-bold text-gray-700">This certifies that</p>
      <p className="text-3xl font-bold text-purple-600 my-4">{studentName}</p>
      <p className="text-xl text-gray-700">has successfully learned about</p>
      <p className="text-2xl font-bold text-pink-500 my-4">Understanding My Feelings</p>
      <p className="text-xl text-gray-700">on</p>
      <p className="text-xl font-bold text-gray-700">{date}</p>
    </div>
  </div>
);

// EmotionWord Component
const EmotionWord = ({ emotion, color, isAvailable, isSelected, onSelect }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'emotion',
    item: { emotion, color },
    canDrag: isAvailable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  if (!isAvailable) return null;

  return (
    <div
      ref={isSelected ? null : drag}
      className={`
        cursor-pointer text-2xl font-bold p-3 rounded-xl inline-block m-2
        bg-white/80 backdrop-blur-sm shadow-md
        transform transition-all duration-300
        hover:scale-110 active:scale-95
        ${isDragging ? 'opacity-50' : 'opacity-100'}
        ${isSelected ? 'ring-4 ring-purple-500' : ''}
      `}
      style={{ color }}
      onClick={onSelect}
    >
      {emotion}
    </div>
  );
};

// Cloud Component
const Cloud = ({ emotion, onDrop, onReset, onClickDrop, index }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'emotion',
    drop: (item) => onDrop(item.emotion, item.color, index),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      const offset = 5;
      const newX = Math.sin(Date.now() / 2000 + index) * offset;
      const newY = Math.cos(Date.now() / 2000 + index) * offset;
      setPosition({ x: newX, y: newY });
    }, 50);

    return () => clearInterval(interval);
  }, [index]);

  return (
    <div
      ref={drop}
      className={`
        relative w-48 h-32 transition-all duration-300
        ${isOver ? 'scale-110' : 'scale-100'}
      `}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        transition: 'transform 2s ease-in-out',
      }}
      onClick={onClickDrop}
    >
      <svg viewBox="0 0 200 100" className="w-full h-full drop-shadow-lg">
        <path
          d="M20,50 Q30,20 50,30 Q70,10 90,30 Q110,10 130,30 Q150,20 160,50 Q180,40 180,60 Q180,80 160,70 Q150,90 130,70 Q110,90 90,70 Q70,90 50,70 Q30,80 20,60 Q10,40 20,50"
          fill={isOver ? '#e0e7ff' : 'white'}
          stroke="#4a5568"
          strokeWidth="2"
        />
      </svg>
      {emotion && (
        <div className="absolute inset-0 flex items-center justify-center text-xl font-bold">
          {emotion}
          <button
            className="absolute top-2 right-2 text-sm bg-red-400 text-white rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-500 transition-colors duration-300"
            onClick={(e) => {
              e.stopPropagation();
              onReset(emotion, index);
            }}
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

// StrategyCard Component
const StrategyCard = ({ index, value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  const icons = [
    <Sparkles className="w-8 h-8 text-yellow-400" />,
    <Star className="w-8 h-8 text-pink-400" />,
    <Heart className="w-8 h-8 text-red-400" />,
    <Sun className="w-8 h-8 text-orange-400" />,
  ];

  const bgColors = [
    'bg-gradient-to-br from-yellow-100 to-yellow-200',
    'bg-gradient-to-br from-pink-100 to-pink-200',
    'bg-gradient-to-br from-red-100 to-red-200',
    'bg-gradient-to-br from-orange-100 to-orange-200',
  ];

  const borderColors = [
    'focus:border-yellow-400',
    'focus:border-pink-400',
    'focus:border-red-400',
    'focus:border-orange-400',
  ];

  return (
    <div
      className={`
      relative p-6 rounded-2xl shadow-lg transform transition-all duration-300
      ${bgColors[index]}
      ${isFocused ? 'scale-105' : 'hover:scale-102'}
    `}
    >
      <div className="absolute -top-2 -right-2 w-4 h-4 rounded-full bg-white/30" />
      <div className="absolute -bottom-1 -left-1 w-3 h-3 rounded-full bg-white/30" />

      <div className="space-y-4">
        <div className="flex items-center justify-center space-x-3">
          {icons[index]}
          <h3 className="text-xl font-bold text-gray-700">My Strategy {index + 1}</h3>
        </div>

        <textarea
          value={value}
          onChange={(e) => onChange(index, e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder="When I feel big emotions, I can..."
          className={`
            w-full h-32 p-4 rounded-xl
            bg-white/80 backdrop-blur-sm
            border-2 border-white/50
            ${borderColors[index]}
            focus:outline-none focus:ring-2 focus:ring-opacity-50
            text-gray-700 placeholder-gray-400
            text-lg
            resize-none
            transition-all duration-300
          `}
        />

        <div className="text-center text-gray-600 text-sm font-medium">
          Write your special way to handle big feelings!
        </div>
      </div>
    </div>
  );
};

// Main Tempactivity1 Component
const LavaActivity1 = ({ hideNavigation = false }) => {
  const initialEmotions = [
    { text: 'happy', color: '#6366f1', isAvailable: true },
    { text: 'sad', color: '#3b82f6', isAvailable: true },
    { text: 'excited', color: '#06b6d4', isAvailable: true },
    { text: 'angry', color: '#ef4444', isAvailable: true },
    { text: 'scared', color: '#8b5cf6', isAvailable: true },
    { text: 'loved', color: '#ec4899', isAvailable: true },
    { text: 'proud', color: '#f59e0b', isAvailable: true },
    { text: 'calm', color: '#10b981', isAvailable: true },
    { text: 'silly', color: '#6366f1', isAvailable: true },
    { text: 'tired', color: '#64748b', isAvailable: true },
    { text: 'worried', color: '#9333ea', isAvailable: true },
    { text: 'nervous', color: '#0ea5e9', isAvailable: true },
  ];

  const [emotions, setEmotions] = useState(initialEmotions);
  const [cloudContents, setCloudContents] = useState(Array(7).fill(null));
  const [strategies, setStrategies] = useState(Array(4).fill(''));
  const [showNameModal, setShowNameModal] = useState(false);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [selectedEmotion, setSelectedEmotion] = useState(null);

  const handleDrop = (emotionText, color, cloudIndex) => {
    setCloudContents((prev) => {
      const newContents = [...prev];
      newContents[cloudIndex] = emotionText;
      return newContents;
    });
    setEmotions((prev) =>
      prev.map((emotion) =>
        emotion.text === emotionText ? { ...emotion, isAvailable: false } : emotion
      )
    );
    setSelectedEmotion(null);
  };

  const handleCloudClick = (cloudIndex) => {
    if (selectedEmotion) {
      handleDrop(selectedEmotion.text, selectedEmotion.color, cloudIndex);
    }
  };

  const handleReset = (emotionText, cloudIndex) => {
    setCloudContents((prev) => {
      const newContents = [...prev];
      newContents[cloudIndex] = null;
      return newContents;
    });

    setEmotions((prev) =>
      prev.map((emotion) =>
        emotion.text === emotionText ? { ...emotion, isAvailable: true } : emotion
      )
    );
  };

  const handleStrategyChange = (index, value) => {
    const newStrategies = [...strategies];
    newStrategies[index] = value;
    setStrategies(newStrategies);
  };

  const handleNameSubmit = () => {
    if (studentName.trim()) {
      setShowNameModal(false);
      setShowCertificateModal(true);
    }
  };

  const handleDownloadCertificate = () => {
    setShowCertificateModal(false);
  };

  const navigate = useNavigate();
  const handleBackToMain = () => {
    navigate(hideNavigation ? '/standalone/lava-island' : '/lava-island');
  };

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <div className="min-h-screen flex flex-col bg-gradient-to-b from-purple-100 via-blue-50 to-pink-100">
        {!hideNavigation && <Header />}
        {!hideNavigation && <NavBar />}

        <main className="flex-1 container mx-auto px-4 py-8">
          <section className="mb-16">
            <div className="text-center mb-12">
              <h1 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 mb-6">
                What are my big feelings?
              </h1>
              <p className="text-xl text-gray-600 mb-2">
                Drag (or tap and drop) your feelings into the clouds!
              </p>
            </div>

            <div className="flex flex-wrap justify-center gap-8 mb-12">
              {cloudContents.map((emotion, index) => (
                <Cloud
                  key={index}
                  index={index}
                  emotion={emotion}
                  onDrop={handleDrop}
                  onReset={handleReset}
                  onClickDrop={() => handleCloudClick(index)}
                />
              ))}
            </div>

            <div className="text-center p-8 bg-white/80 backdrop-blur-sm rounded-xl shadow-lg">
              <div className="flex flex-wrap justify-center gap-4">
                {emotions.map((emotion) => (
                  <EmotionWord
                    key={emotion.text}
                    emotion={emotion.text}
                    color={emotion.color}
                    isAvailable={emotion.isAvailable}
                    isSelected={selectedEmotion?.text === emotion.text}
                    onSelect={() =>
                      setSelectedEmotion(
                        selectedEmotion?.text === emotion.text ? null : emotion
                      )
                    }
                  />
                ))}
              </div>
            </div>
          </section>

          <section className="mt-16 pt-16 border-t-4 border-purple-200">
            <div className="text-center mb-12">
              <h2 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 mb-6">
                When I have big feelings I can...
              </h2>
              <p className="text-xl text-gray-600 mb-8">
                Share your special strategies to handle your feelings!
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto px-4">
              {strategies.map((strategy, index) => (
                <StrategyCard
                  key={index}
                  index={index}
                  value={strategy}
                  onChange={handleStrategyChange}
                />
              ))}
            </div>

            <div className="text-center mt-16">
              <button
                onClick={() => setShowNameModal(true)}
                className="
                  px-12 py-4 text-xl font-bold text-white
                  rounded-full
                  bg-gradient-to-r from-purple-500 to-pink-500
                  hover:from-purple-600 hover:to-pink-600
                  transform hover:-translate-y-1
                  transition-all duration-300
                  shadow-lg hover:shadow-xl
                  flex items-center justify-center mx-auto space-x-2
                "
              >
                <span>I'm Done!</span>
                <Star className="w-6 h-6" />
              </button>
            </div>
            <button
              onClick={handleBackToMain}
              className="absolute top-4 left-4 bg-white/80 hover:bg-white text-red-600 font-bold px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 border-2 border-red-400 shadow-md flex items-center gap-2"
            >
              ← Back to Lava Island
            </button>
          </section>
        </main>

        {/* <Footer /> */}
        {!hideNavigation && <Footer />}

        <Modal isOpen={showNameModal} onClose={() => setShowNameModal(false)}>
          <div className="text-center">
            <div className="mb-8">
              <Sparkles className="w-12 h-12 text-yellow-400 mx-auto mb-4" />
              <h2 className="text-2xl font-bold text-gray-800 mb-2">Great Job! 🌟</h2>
              <p className="text-gray-600">Tell us your name to get your special certificate!</p>
            </div>
            <input
              type="text"
              value={studentName}
              onChange={(e) => setStudentName(e.target.value)}
              placeholder="Type your name..."
              className="
                w-full p-4 text-lg
                border-2 border-purple-200
                rounded-xl mb-6
                focus:outline-none focus:border-purple-400 focus:ring-2 focus:ring-purple-100
                text-center
                placeholder-gray-400
                transition-all duration-300
              "
            />
            <button
              onClick={handleNameSubmit}
              className="
                w-full py-3 px-6
                text-lg font-bold text-white
                rounded-xl
                bg-gradient-to-r from-purple-500 to-pink-500
                hover:from-purple-600 hover:to-pink-600
                transform hover:-translate-y-1
                transition-all duration-300
                shadow-md hover:shadow-lg
                flex items-center justify-center space-x-2
              "
            >
              <span>Get My Certificate!</span>
              <Star className="w-5 h-5" />
            </button>
          </div>
        </Modal>

        <Modal isOpen={showCertificateModal} onClose={() => setShowCertificateModal(false)}>
          <div className="text-center">
            <Certificate 
              studentName={studentName}
              date={new Date().toLocaleDateString()}
            />
            <button
              onClick={handleDownloadCertificate}
              className="
                mt-6 px-8 py-3
                text-lg font-bold text-white
                rounded-xl
                bg-gradient-to-r from-purple-500 to-pink-500
                hover:from-purple-600 hover:to-pink-600
                transform hover:-translate-y-1
                transition-all duration-300
                shadow-md hover:shadow-lg
                flex items-center justify-center mx-auto space-x-2
              "
            >
              <span>Download My Certificate! 🎉</span>
              <Star className="w-5 h-5" />
            </button>
          </div>
        </Modal>
      </div>
    </DndProvider>
  );
};

export default LavaActivity1;