// src/standalone/lavaisland/StandaloneBuddyIsland.js
import LavaIsland from '../../pages/LavaIsland';

const StandaloneLavaIsland = () => {
  return (
    <div className="min-h-screen">
      <LavaIsland hideNavigation={true} />
    </div>
  );
};

export default StandaloneLavaIsland;