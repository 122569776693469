// src/standalone/StandaloneBuddyIsland.js
import BuddyIsland from '../../pages/BuddyIsland';

const StandaloneBuddyIsland = () => {
  return (
    <div className="min-h-screen">
      <BuddyIsland hideNavigation={true} />
    </div>
  );
};

export default StandaloneBuddyIsland;