// src/standalone/togetherland/StandaloneTogetherActivity3.js
import TogetherActivity3 from "../../pages/together-land/together-activity3";

const StandaloneTogetherActivity3 = () => {
  return (
    <div className="min-h-screen">
      <TogetherActivity3 hideNavigation={true} />
    </div>
  );
};

export default StandaloneTogetherActivity3;