import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';

const breathingSlides = [
  {
    title: "What is Mindfulness?",
    content: (
      <div>
        <p className="mb-4">Mindfulness is like your very own superpower! It means paying attention to what's happening right now - both outside and inside your body.</p>
        <p>Just like a superhero observing their surroundings, you can notice your thoughts and feelings without judging them.</p>
      </div>
    )
  },
  {
    title: "Why Practice Mindfulness?",
    content: (
      <div>
        <p className="mb-4">Sometimes our minds feel super busy, like a jar full of shaken-up glitter! Mindfulness helps:</p>
        <ul className="space-y-2 list-disc pl-6">
          <li>Calm down when feeling worried</li>
          <li>Focus better in school</li>
          <li>Handle big feelings</li>
          <li>Feel happier</li>
        </ul>
      </div>
    )
  },
  {
    title: "Square Breathing",
    content: (
      <div className="text-center">
        <img src="/images/lavaisland/square.png" alt="Square breathing demonstration" className="mx-auto mb-4" />
        <ol className="text-left space-y-2 list-decimal pl-6">
          <li>Imagine drawing a square in the air</li>
          <li>Breathe in while drawing the first line (count to 4)</li>
          <li>Hold your breath while drawing the second line (count to 4)</li>
          <li>Breathe out while drawing the third line (count to 4)</li>
          <li>Wait while drawing the last line (count to 4)</li>
        </ol>
      </div>
    )
  },
  {
    title: "Dragon Breathing",
    content: (
      <div className="text-center">
        <img src="/images/lavaisland/dragon.jpg" alt="Dragon breathing demonstration" className="mx-auto mb-4" />
        <ol className="text-left space-y-2 list-decimal pl-6">
          <li>Sit up tall like a mighty dragon</li>
          <li>Take a deep breath in through your nose</li>
          <li>Breathe out through your mouth like you're breathing fire!</li>
        </ol>
      </div>
    )
  },
  {
    title: "Let's Practice Together!",
    content: (
      <div className="text-center">
        <img src="/images/lavaisland/breathingactivity.gif" alt="Breathing practice animation" className="mx-auto mb-4" />
        <p>Follow along with the animation</p>
      </div>
    )
  }
];

const LavaActivity3 = ({ hideNavigation = false }) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [clouds, setClouds] = useState({
    cloud1: '',
    cloud2: '',
    cloud3: '',
    cloud4: '',
    cloud5: ''
  });

  const handleCloudChange = (cloudKey, value) => {
    setClouds(prev => ({
      ...prev,
      [cloudKey]: value
    }));
  };

  const handleSubmit = () => {
    console.log('Submitted:', { name, clouds });
    navigate('/lava-island');
  };

  const formatName = (name) => {
    if (name.length > 15) {
      return `${name.slice(0, 15)}...`;
    }
    return name;
  };

  const goToNextSlide = () => {
    setCurrentSlide((prev) => 
      prev === breathingSlides.length - 1 ? prev : prev + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prev) => 
      prev === 0 ? prev : prev - 1
    );
  };

  const handleBackToMain = () => {
    navigate(hideNavigation ? '/standalone/lava-island' : '/lava-island');
  };

  return (
    <div className="flex flex-col min-h-screen w-full bg-gradient-to-b from-purple-100 via-blue-50 to-pink-100">
      {!hideNavigation && <Header />}
      {!hideNavigation && <NavBar />}
      <main className="flex-1 p-4 md:p-8">
        <div className="max-w-4xl mx-auto bg-white/30 backdrop-blur-sm rounded-3xl p-6 md:p-8 shadow-xl">
          {/* Introduction Text */}
          <div className="mb-8 text-gray-800">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Building an Emotional Toolkit</h2>
            <p className="mb-4">
              Let's build something special together—your very own Emotional Toolkit! 
              This toolkit will help you feel better when things get tough. 
              You can write your ideas on this page or even create a real box filled with your favorite tools.
            </p>
            <p className="mb-4">Your Emotional Toolkit can include:</p>
            <ul className="list-disc pl-6 mb-4 space-y-2">
              <li>Things that make you feel calm (like deep breaths or a favorite relaxation trick we've talked about).</li>
              <li>Happy thoughts or reminders (like, <em>"This feeling will go away"</em> or <em>"I am safe"</em>).</li>
              <li>Special objects (like a <em>toy</em>, <em>blanket</em>, or anything that makes you feel happy).</li>
            </ul>
            <p className="mb-8">You can get as creative as you want! Let's make this toolkit yours!</p>
          </div>

          <h1 className="text-3xl md:text-4xl font-bold text-gray-800 text-center mb-6">
            My Emotional Toolkit
          </h1>
          
          {/* Name Input Section */}
          <div className="mb-8 text-center">
            <label className="block text-gray-800 text-lg md:text-xl mb-2">
              What's your name? (Write it here so this toolkit belongs to you!)
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={30}
              className="px-4 py-2 rounded-full bg-white/50 text-gray-800 border border-gray-300 
                         focus:outline-none focus:ring-2 focus:ring-blue-300 w-64 md:w-72"
              placeholder="Your name here"
            />
          </div>

          {/* Main Activity Area */}
          <div className="relative w-full aspect-[4/3] mb-8">
            {/* Instructions */}
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 
                           text-gray-600 text-sm bg-white/70 rounded-full px-4 py-1">
              Click on any cloud to write something that helps you feel better!
            </div>

            {/* Background Image Container */}
            <div className="w-full h-full flex justify-center items-center bg-white rounded-xl p-4">
              <img
                src="/images/lavaisland/toolkit.png"
                alt="Emotional Toolkit"
                className="w-full h-full object-contain"
              />

              {/* Name Display on Toolkit */}
              <div className="absolute top-[67%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[80%] break-words text-center">
                <span className="text-blue-600 font-bold text-xl md:text-3xl lg:text-3xl">
                  {formatName(name)}
                </span>
              </div>

              {/* Cloud Inputs */}
              <div className="absolute inset-0">
                {/* Top Row */}
                <textarea
                  value={clouds.cloud1}
                  onChange={(e) => handleCloudChange('cloud1', e.target.value)}
                  className="absolute top-[22%] left-[13%] w-26 h-24 md:w-40 md:h-28 lg:w-26 lg:h-32 
                           bg-transparent p-2 resize-none text-gray-700 placeholder-gray-500
                           focus:outline-none border-none rounded-lg"
                  placeholder="Write your thoughts..."
                  maxLength={150}
                />
                <textarea
                  value={clouds.cloud2}
                  onChange={(e) => handleCloudChange('cloud2', e.target.value)}
                  className="absolute top-[20%] left-[42%] w-26 h-24 md:w-40 md:h-28 lg:w-26 lg:h-32 
                           bg-transparent p-2 resize-none text-gray-700 placeholder-gray-500
                           focus:outline-none border-none rounded-lg"
                  placeholder="Write your thoughts..."
                  maxLength={150}
                />
                <textarea
                  value={clouds.cloud3}
                  onChange={(e) => handleCloudChange('cloud3', e.target.value)}
                  className="absolute top-[22%] right-[14%] w-26 h-24 md:w-40 md:h-28 lg:w-26 lg:h-32 
                           bg-transparent p-2 resize-none text-gray-700 placeholder-gray-500
                           focus:outline-none border-none rounded-lg"
                  placeholder="Write your thoughts..."
                  maxLength={150}
                />

                {/* Bottom Row */}
                <textarea
                  value={clouds.cloud4}
                  onChange={(e) => handleCloudChange('cloud4', e.target.value)}
                  className="absolute bottom-[30%] left-[13%] w-26 h-24 md:w-40 md:h-28 lg:w-26 lg:h-32 
                           bg-transparent p-2 resize-none text-gray-700 placeholder-gray-500
                           focus:outline-none border-none rounded-lg"
                  placeholder="Write your thoughts..."
                  maxLength={150}
                />
                <textarea
                  value={clouds.cloud5}
                  onChange={(e) => handleCloudChange('cloud5', e.target.value)}
                  className="absolute bottom-[33%] right-[12%] w-26 h-24 md:w-40 md:h-28 lg:w-26 lg:h-32 
                           bg-transparent p-2 resize-none text-gray-700 placeholder-gray-500
                           focus:outline-none border-none rounded-lg"
                  placeholder="Write your thoughts..."
                  maxLength={150}
                />
              </div>
            </div>
          </div>

          {/* Mindful Breathing Section */}
          <div className="mb-12 bg-white/40 backdrop-blur-sm rounded-2xl p-6 shadow-lg">
            <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center text-gray-800">
              Mindful Breathing Journey
            </h2>
            
            <div className="min-h-[400px] mb-6">
              <h3 className="text-xl md:text-2xl font-bold mb-6 text-gray-700">
                {breathingSlides[currentSlide].title}
              </h3>
              <div className="prose prose-lg">
                {breathingSlides[currentSlide].content}
              </div>
            </div>
            
            <div className="flex justify-between items-center mt-8">
              <button
                onClick={goToPrevSlide}
                disabled={currentSlide === 0}
                className={`flex items-center gap-2 px-4 py-2 rounded-full ${
                  currentSlide === 0 
                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-blue-400 to-purple-400 text-white hover:from-blue-500 hover:to-purple-500'
                }`}
              >
                <ChevronLeft className="w-5 h-5" />
                Back
              </button>
              
              <div className="text-sm text-gray-600">
                {currentSlide + 1} / {breathingSlides.length}
              </div>
              
              <button
                onClick={goToNextSlide}
                disabled={currentSlide === breathingSlides.length - 1}
                className={`flex items-center gap-2 px-4 py-2 rounded-full ${
                  currentSlide === breathingSlides.length - 1
                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                    : 'bg-gradient-to-r from-blue-400 to-purple-400 text-white hover:from-blue-500 hover:to-purple-500'
                }`}
              >
                Next
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>

          {/* Submit Button */}
          {/* <div className="text-center">
            <button
              onClick={handleSubmit}
              className="bg-gradient-to-r from-blue-400 to-purple-400 text-white px-8 py-3 
                       rounded-full text-lg hover:from-blue-500 hover:to-purple-500 
                       transition-all duration-300 transform hover:-translate-y-1 
                       shadow-lg hover:shadow-xl font-bold"
            >
              Save My Toolkit
            </button>
          </div> */}
        </div>
        <button
          onClick={handleBackToMain}
          className="absolute top-4 left-4 bg-white/80 hover:bg-white text-red-600 font-bold px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 border-2 border-red-400 shadow-md flex items-center gap-2"
        >
          ← Back to Lava Island
        </button>
      </main>
      {/* <Footer /> */}
      {!hideNavigation && <Footer />}
    </div>
  );
};

export default LavaActivity3;