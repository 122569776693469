// src/standalone/lavaisland/StandaloneBuddyIsland.js
import TogetherLand from '../../pages/TogetherLand';

const StandaloneTogetherLand = () => {
  return (
    <div className="min-h-screen">
      <TogetherLand hideNavigation={true} />
    </div>
  );
};

export default StandaloneTogetherLand;