// src/standalone/togetherland/StandaloneTogetherActivity1.js
import TogetherActivity1 from "../../pages/together-land/together-activity1";

const StandaloneTogetherActivity1 = () => {
  return (
    <div className="min-h-screen">
      <TogetherActivity1 hideNavigation={true} />
    </div>
  );
};

export default StandaloneTogetherActivity1;