import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import NavBar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Confetti from 'react-confetti';

const scenarios = [
  {
    id: 1,
    situation: "Alex is new to the school and doesn't know anyone during lunch time.",
    perspective: "I feel nervous and lonely. I wish someone would invite me to sit with them.",
    responses: [
      "I could invite them to sit at my table",
      "I could introduce them to my friends",
      "I could ask them about their old school"
    ]
  },
  {
    id: 2,
    situation: "Sam's art project got accidentally ruined by another student.",
    perspective: "I worked so hard on it, and now I feel really upset and disappointed.",
    responses: [
      "I could help them fix it",
      "I could share my art supplies",
      "I could tell the teacher what happened"
    ]
  },
  {
    id: 3,
    situation: "Jordan's family celebrates a holiday that's different from most other students.",
    perspective: "Sometimes I feel different when others don't understand my traditions.",
    responses: [
      "I could ask them to tell me about their celebration",
      "I could share something about my own family traditions",
      "I could invite them to teach the class about their holiday"
    ]
  },
  {
    id: 4,
    situation: "Pat gets nervous when speaking in front of the class.",
    perspective: "My heart beats fast and I'm afraid I'll make a mistake.",
    responses: [
      "I could give them an encouraging smile",
      "I could tell them it's okay to be nervous",
      "I could practice with them before class"
    ]
  },
  {
    id: 5,
    situation: "Casey's pet is sick and they seem sad today.",
    perspective: "I'm worried about my pet and can't focus on anything else.",
    responses: [
      "I could listen to them talk about their pet",
      "I could share a story about when my pet was sick",
      "I could make them a cheerful card"
    ]
  },
  {
    id: 6,
    situation: "Taylor is having trouble with math and feels frustrated.",
    perspective: "Everyone else seems to understand it easily. I feel left behind.",
    responses: [
      "I could offer to study together during lunch",
      "I could share my math notes and tips",
      "I could remind them that everyone learns differently"
    ]
  },
  {
    id: 7,
    situation: "Jamie dropped their lunch in the cafeteria and everyone laughed.",
    perspective: "I feel embarrassed and want to disappear. My face feels hot.",
    responses: [
      "I could help them clean up",
      "I could share my lunch with them",
      "I could tell others it's not funny"
    ]
  },
  {
    id: 8,
    situation: "Riley's team lost the big game and they're feeling down.",
    perspective: "We practiced so hard. I feel like I let everyone down.",
    responses: [
      "I could tell them they played really well",
      "I could remind them winning isn't everything",
      "I could suggest practicing together for next time"
    ]
  }
];

const BuddyActivity1 = ({ hideNavigation = false }) => {
  const navigate = useNavigate();
  const [currentScenario, setCurrentScenario] = useState(0);
  const [showPerspective, setShowPerspective] = useState(false);
  const [showResponses, setShowResponses] = useState(false);
  const [selectedResponses, setSelectedResponses] = useState([]);
  const [completedScenarios, setCompletedScenarios] = useState(new Set());
  const [showCompletion, setShowCompletion] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const handleNext = () => {
    if (selectedResponses.length > 0) {
      setCompletedScenarios(prev => new Set([...prev, currentScenario]));
    }
    
    if (currentScenario < scenarios.length - 1) {
      setCurrentScenario(prev => prev + 1);
      setShowPerspective(false);
      setShowResponses(false);
      setSelectedResponses([]);
    } else if (currentScenario === scenarios.length - 1) {
      setShowCompletion(true);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000);
    }
  };

  const handlePrevious = () => {
    if (currentScenario > 0) {
      setCurrentScenario(prev => prev - 1);
      setShowPerspective(false);
      setShowResponses(false);
      setSelectedResponses([]);
    }
  };

  const handleResponseSelect = (response) => {
    setSelectedResponses(prev => {
      if (prev.includes(response)) {
        return prev.filter(r => r !== response);
      }
      return [...prev, response];
    });
  };

  const handleBackToMain = () => {
    navigate(hideNavigation ? '/standalone/buddy-island' : '/buddy-island');
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-green-500 to-emerald-500">
      {showConfetti && <Confetti />}
      {!hideNavigation && <Header />}
      {!hideNavigation && <NavBar />}

      <main className="flex-1 relative px-4 py-8">
        <button
          onClick={handleBackToMain}
          className="absolute top-4 left-4 bg-white/80 hover:bg-white text-green-700 font-bold px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 border-2 border-green-500 shadow-md"
        >
          ← Back to Buddy Island
        </button>

        {/* Progress Bar */}
        <div className="max-w-4xl mx-auto mb-8">
          <div className="flex items-center justify-between mb-2 bg-white/20 p-4 rounded-xl">
            {scenarios.map((_, index) => (
              <div
                key={index}
                className={`w-16 h-4 rounded-full transition-all duration-300 ${
                  completedScenarios.has(index)
                    ? 'bg-green-400 animate-pulse'
                    : index === currentScenario
                    ? 'bg-white'
                    : 'bg-white/30'
                }`}
              />
            ))}
          </div>
          <p className="text-center text-white font-bold text-lg">
            Scenario {currentScenario + 1} of {scenarios.length}
          </p>
        </div>

        {!showCompletion ? (
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-8">
              <h1 className="text-5xl font-bold text-white mb-4 animate-bounce-slow">Lending Ear</h1>
              <p className="text-2xl text-white">
                Understanding Others' Experiences
              </p>
            </div>

            <div className="bg-white/90 backdrop-blur-md rounded-3xl border-4 border-white p-8 shadow-2xl">
              <div className="bg-green-100 rounded-xl p-6 mb-6 transform transition-all duration-300">
                <h2 className="text-3xl font-bold text-green-600 mb-4">
                  Scenario {currentScenario + 1}:
                </h2>
                <p className="text-xl text-green-700 mb-6">
                  {scenarios[currentScenario].situation}
                </p>
                
                {!showPerspective && (
                  <button
                    onClick={() => setShowPerspective(true)}
                    className="bg-green-500 text-white px-8 py-4 rounded-full hover:bg-green-600 transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg"
                  >
                    How might they feel? 🤔
                  </button>
                )}

                {showPerspective && (
                  <div className="mt-6 animate-fade-in">
                    <div className="bg-green-200 rounded-xl p-6 mb-6 border-2 border-green-300">
                      <p className="text-xl text-green-800 italic">
                        "{scenarios[currentScenario].perspective}"
                      </p>
                    </div>
                    
                    {!showResponses && (
                      <button
                        onClick={() => setShowResponses(true)}
                        className="bg-green-500 text-white px-8 py-4 rounded-full hover:bg-green-600 transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg"
                      >
                        How can we help? 💡
                      </button>
                    )}
                  </div>
                )}

                {showResponses && (
                  <div className="mt-6 animate-fade-in">
                    <h3 className="text-2xl font-bold text-green-600 mb-4">
                      Choose ways to show empathy:
                    </h3>
                    <div className="space-y-4">
                      {scenarios[currentScenario].responses.map((response, index) => (
                        <button
                          key={index}
                          onClick={() => handleResponseSelect(response)}
                          className={`w-full text-left p-6 rounded-xl transition-all duration-300 transform hover:scale-102 text-lg font-semibold ${
                            selectedResponses.includes(response)
                              ? 'bg-green-400 text-white shadow-lg scale-105'
                              : 'bg-green-200 text-green-800 hover:bg-green-300'
                          }`}
                        >
                          {response}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-between mt-6">
                <button
                  onClick={handlePrevious}
                  disabled={currentScenario === 0}
                  className={`px-8 py-4 rounded-full transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg ${
                    currentScenario === 0
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-green-500 hover:bg-green-600'
                  } text-white`}
                >
                  ← Previous
                </button>
                <button
                  onClick={handleNext}
                  disabled={!selectedResponses.length}
                  className={`px-8 py-4 rounded-full transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg ${
                    !selectedResponses.length
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-green-500 hover:bg-green-600'
                  } text-white`}
                >
                  Next →
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="max-w-2xl mx-auto text-center bg-white/90 rounded-3xl p-12 animate-bounce-slow">
            <h2 className="text-5xl font-bold text-green-600 mb-6">🎉 Great Job! 🎉</h2>
            <p className="text-2xl text-green-700 mb-8">
              You've completed all the scenarios and learned so much about understanding others' feelings!
            </p>
            <button
              onClick={handleBackToMain}
              className="bg-green-500 text-white px-8 py-4 rounded-full hover:bg-green-600 transition-all duration-300 transform hover:scale-105 text-xl font-bold shadow-lg"
            >
              Back to Buddy Island →
            </button>
          </div>
        )}
      </main>
      {/* <Footer /> */}
      {!hideNavigation && <Footer />}

      <style jsx>{`
        @keyframes bounce-slow {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }

        @keyframes fade-in {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }

        .animate-bounce-slow {
          animation: bounce-slow 3s infinite;
        }

        .animate-fade-in {
          animation: fade-in 0.5s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default BuddyActivity1;