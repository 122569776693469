// src/standalone/StandaloneBuddyActivity3.js
import BuddyActivity3 from '../../pages/buddy-island/buddy-activity3';

const StandaloneBuddyActivity3 = () => {
  return (
    <div className="min-h-screen">
      <BuddyActivity3 hideNavigation={true} />
    </div>
  );
};

export default StandaloneBuddyActivity3;